// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import translations
import translationEN from "./locales/en/translation.json"; // English
import translationPT from "./locales/pt/translation.json"; // Portuguese (example)
import translationES from "./locales/es/translation.json"; // Spanish (example)
const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "es", // Set the default language
    keySeparator: ".", // Allow for nested translations without using dots
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
