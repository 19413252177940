import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [successSendEmail, setSuccessSendEmail] = useState(false);
  const { t } = useTranslation();

  const templateID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
  const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
  const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

  function sendEmail(e) {
    e.preventDefault();

    const templateParams = {
      name: e.target.name.value,
      email: e.target.email.value,
      company: e.target.company.value,
      subject: e.target.subject.value,
      message: e.target.message.value,
    };

    emailjs
      .send(serviceID, templateID, templateParams, { publicKey: publicKey })
      .then(() => {
        setSuccessSendEmail(true);

        setTimeout(() => {
          setSuccessSendEmail(false);
      }, 3000);

      });
    e.target.reset();
  }

  return (
    <section
      className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
      id="contact"
    >
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h6 className="text-orange-600 text-base font-medium uppercase mb-2">
            {t("Contact.contactUs")}
          </h6>
          <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
            {t("Contact.improve")}
          </h3>

          <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
            {t("Contact.tellUs")}
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
          <div className="lg:col-span-8">
            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
              <form onSubmit={sendEmail}>
                <div className="grid lg:grid-cols-12 lg:gap-6">
                  <div className="lg:col-span-6 mb-5">
                    <input
                      name="name"
                      id="name"
                      type="text"
                      className="form-input"
                      placeholder={t("Contact.name")}
                      required
                    />
                  </div>

                  <div className="lg:col-span-6 mb-5">
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-input"
                      placeholder={t("Contact.email")}
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="mb-5">
                    <input
                      name="company"
                      id="company"
                      className="form-input"
                      placeholder={t("Contact.company")}
                      required
                    />
                  </div>
                  <div className="mb-5">
                    <input
                      name="subject"
                      id="subject"
                      className="form-input"
                      placeholder={t("Contact.subject")}
                      required
                    />
                  </div>

                  <div className="mb-5">
                    <textarea
                      name="message"
                      id="message"
                      className="form-input textarea h-28"
                      placeholder={t("Contact.message")}
                      required
                    ></textarea>
                  </div>
                </div>
                <button
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center"
                >
                  {t("Contact.sendMessage")}
                </button>
              </form>
              {successSendEmail && (
                <p className="text-orange-600 text-xl mt-1">
                  {t("Contact.successMessage")}
                </p>
              )}
            </div>
          </div>

          <div className="lg:col-span-4">
            <div className="lg:ms-8">
              <div className="flex">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">
                    {t("Contact.phone")}
                  </h5>
                  <a href="tel:+152534-468-854" className="text-slate-400">
                    +598 98-190-625
                  </a>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">
                    Email
                  </h5>
                  <a
                    href="mailto:contact@analabs.tech"
                    className="text-slate-400"
                  >
                    contact@analabs.tech
                  </a>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="text-lg dark:text-white mb-2 font-medium">
                    {t("Contact.location")}
                  </h5>
                  <p className="text-slate-400 mb-2">
                    {t("Contact.locationInfo")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
