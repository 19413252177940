import React from "react";

import "tiny-slider/dist/tiny-slider.css";
import ortLogo from "../assets/images/support/ort-logo.webp";
import { useTranslation } from "react-i18next";

export default function Supporters() {
  const { t } = useTranslation();

  const supporter = [
    {
      id: "1",
      link: "https://cie.ort.edu.uy/emprendimientos/analabs",
      imageSrc: ortLogo,
      name: "ORT",
    },
  ];

  return (
    <>
      <section
        className="relative md:py-24 md:pt-0 py-16 pt-6 bg-gray-50 dark:bg-slate-800"
        id="support"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="text-orange-600 mb-4 md:text-2xl text-xl font-medium uppercase ">
              {t("Support.title")}
            </h3>
          </div>

          <div className="grid grid-cols-1 relative">
            <div className="flex items-center justify-center">
              {supporter.map(({ id, name, link, imageSrc }) => (
                <a
                  key={id}
                  className=" block w-[355px]"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt={name} src={imageSrc} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
