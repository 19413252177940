import React from "react";

const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const LanguageSwitcher = () => {
  const currentLang = getQueryParam("lng") || "es";

  return (
    <div className="flex absolute right-2 border border-gray-400 w-[130px]">
      <a
        className={`nav-link border-r border-gray-400 ${
          currentLang === "es" ? "text-orange-500" : ""
        }`}
        href={"?lng=es"}
      >
        Es
      </a>
      <a
        className={`nav-link border-r border-gray-400 ${
          currentLang === "en" ? "text-orange-500" : ""
        }`}
        href={"?lng=en"}
      >
        En
      </a>
      <a
        className={`nav-link ${currentLang === "pt" ? "text-orange-500" : ""}`}
        href={"?lng=pt"}
      >
        Pt
      </a>
    </div>
  );
};
export default LanguageSwitcher;
